<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">

        <b-row>
            <b-col cols="12" xl="6" md="6">
                <b-card no-body  class="mb-0">

                    <div class="m-1">
                        <!-- <b-card-header>
                            <b-card-title>
                                <h4 class="mb-0_">Margen de Documentos</h4>
                            </b-card-title>
                        </b-card-header> -->
                        <b-row>
                            <b-col cols="12" md="4">
                                <b-form-group label="Tipo Documento" label-for="document_type" label-class="font-weight-bold">
                                    <v-select
                                        :disabled="searchQuery != '' && docto.details.length > 0"
                                        v-model="typeDocument"
                                        id="document_type" 
                                        :options="[
                                            { value: 'V', name: 'Venta' },
                                            { value: 'O', name: 'Ordenes de Venta' },
                                            { value: 'C', name: 'Cotizaciones' },
                                        ]"                        
                                        label="name"
                                        :reduce="option => option.value"
                                        :clearable="false"
                                        :searchable="false"
                                        class="select-size-sm"
                                        placeholder="Selecciona una opción">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="8">
                                <b-form-group label="Ingresar Código de Barras" label-for="search-invoice">
                                    <b-input-group class="input-group-merge">
                                        <b-form-input ref="searcInvoice" size="sm"
                                            :readonly="searchQuery != '' && docto.details.length > 0"
                                            id="search-invoice" v-model="searchQuery" @keydown.enter="searchDocument" placeholder="Ingresa o escanea el código de barras"
                                            autocomplete="off" inputmode="numeric" autofocus type="text" />
                                            <b-input-group-append>
                                                <b-button @click="searchDocument" size="sm" variant="outline-primary">
                                                    <feather-icon icon="SearchIcon" />
                                                </b-button>
                                                <b-button v-if="searchQuery != ''" @click="resetForm" size="sm" variant="outline-danger">
                                                    <feather-icon icon="XIcon" />
                                                </b-button>
                                            </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>  
                    </div>

                    <!-- Tabla -->
                    <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                    :class="`${docto.details.length > 10 ? 'b-table-sticky-header' : ''}`">
                        <table ref="articlesTable" class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                            <thead>
                                <tr>
                                    <th style="font-size: 10px!important;">Clave</th>
                                    <th style="font-size: 10px!important;">Articulo</th>
                                    <th class="text-center" style="font-size: 10px!important;">U. Costo</th>
                                    <th class="text-center" style="font-size: 10px!important;">Precio</th>
                                    <th style="font-size: 10px!important;">Desc</th>
                                    <th style="font-size: 10px!important;">Margen</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in docto.details" :key="index" @click="selectRow(item, index)"
                                    :class="{ 
                                        'selected-row': isSelected(index),
                                    }">
                                    <td>{{ item.clave_Articulo }}</td>
                                    <td>{{ item.articulo }}</td>
                                    <td>
                                        <b-form-input v-model="item.ultimo_Costo" @input="calculateMargin" size="sm" type="number" min="0" step="1" placeholder="Ultimo Costo"/>
                                    </td>
                                    <td>
                                        <b-form-input v-model="item.precio_Unitario" @input="calculateMargin" size="sm" type="number" min="0" step="1" placeholder="Precio Unitario"/>
                                    </td>
                                    <td class="text-right">{{ item.dscto_Art }} %</td>
                                    <td class="text-right">{{ item.margen }} %</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>  
                    
                    <b-card-footer v-if="docto.details.length > 0">
                        <b-row>
                            <b-col cols="12" md="6">
                                <table class="border table text-small small w-100 ">
                                    <thead>
                                        <tr>
                                            <th style="font-size: 10px!important;"></th>
                                            <th style="font-size: 10px!important;">% Impto</th>
                                            <th style="font-size: 10px!important;">$ Impto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in docto.taxes" :key="index">
                                            <td style="font-size: 11px!important;"><strong>{{ item.impuesto.nombre }}</strong></td>
                                            <td style="font-size: 11px!important;" class="text-center">{{ item.pctje_Impuesto }}</td>
                                            <td style="font-size: 11px!important;" class="text-center">{{ item.importe_Impuesto }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </b-col>

                            <b-col cols="12" md="4" class="ml-auto">
                                <b-form-group label="Impuesto Total" label-for="tax" label-class="font-weight-bold">
                                    <b-form-input readonly :value="docto.total_Impuestos" size="sm" type="number" placeholder="Impuesto Total" />
                                </b-form-group>

                                <b-form-group label="Margen Total" label-for="tax" label-class="font-weight-bold">
                                    <b-form-input readonly :value="totalMargin" size="sm" type="number" placeholder="Margen Total" />
                                </b-form-group>

                                <b-form-group label="Margen %" label-for="tax" label-class="font-weight-bold">
                                    <b-form-input readonly :value="percentMargin" size="sm" type="number" placeholder="Margen Porcentaje" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-footer>
                </b-card>
            </b-col>

            <b-col ref="invoiceActions" v-if="article.articulo_Id > 0" cols="12" md="6" xl="6" class="invoice-actions">
                <b-card no-body class="border-primary">
                    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
                        <h5 class="mb-0">{{ article.articulo }}</h5>
                        <b-badge variant="light-primary">Clave: {{ article.clave_Articulo }}</b-badge>
                        <!-- <small class="text-muted w-100 text-capitalize">
                           <strong>Folio: {{ article.folio }}, Fecha: {{ customDate(article.fecha, 'MMMM d, YYYY') }}</strong>
                        </small> -->
                    </b-card-header>

                    <b-tabs content-class="pt-1">
                        <b-tab v-model="activeTab" lazy title="Últimas Compras">
                            <last-purchases v-if="article.articulo_Id > 0"
                                :active="activeTab === 'purchases'"
                                :article="article"
                                :connection="getConnectionId"
                                :branch="getBranchId"
                                :warehouse="0" />
                        </b-tab>

                        <b-tab lazy title="Kardex">
                            <kardex-component v-if="article.articulo_Id > 0"
                                :active="activeTab === 'kardex'"
                                :article="article"
                                :connection="getConnectionId"
                                :branch="getBranchId"/>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
        
    </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab,  BTooltip, VBTooltip,
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import { VueGoodTable } from 'vue-good-table'
import { heightTransition } from '@core/mixins/ui/transition'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store';
import * as helper from '@/libs/helpers';
import LastPurchases from '@core/components/purchase-customizer/LastPurchases.vue';
import KardexComponent from '@core/components/purchase-customizer/Kardex.vue';
import DoctosVEProvider from '@/providers/DoctosVE';
const DoctosVEResource = new DoctosVEProvider();

import DoctosPvProvider from '@/providers/DoctosPV';
const DoctosPVResource = new DoctosPvProvider();


export default {
    name: 'MarginDocuments',
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        VueGoodTable,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,
        BTooltip, 
        VBTooltip,

        //
        vSelect,
        LastPurchases,
        KardexComponent,
    },
    mixins: [heightTransition],
    setup() {
        const loading = ref(false); 
        const typeDocument = ref('V');
        const searchQuery = ref(''); 
        const selectRowIndex = ref(-1);
        const lastPurchases = ref([]);
        const activeTab = ref('purchases');
        const columnLastPurchases = [
            {
                key: 'proveedor',
                label: 'Proveedor',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'recepcion',
                label: 'Recepción',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'fecha',
                label: 'Fecha',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'costo',
                label: 'Costo',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'costoReal',
                label: 'Costo Real',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
            {
                key: 'unidades',
                label: 'Unidades',
                visible: true,
                tdClass: 'text-nowrap text-small',
                tdStyle: 'font-size: 4px!important',
                thStyle: 'font-size: 8px!important'
            },
        ]
        const article = ref({
            articulo_Id: 0,
        });
        const docto = ref({
            importe_Neto: 0,
            total_Impuestos: 0,
            details: [],
            taxes: [],
        });  

        const resetForm = () => {
            selectRowIndex.value = -1;
            searchQuery.value = '';
            docto.value = {
                importe_Neto: 0,
                total_Impuestos: 0,
                details: [],
                taxes: [],
            };
        }

        const isSelected = (index) => {
            return index === selectRowIndex.value;
        }

        const selectRow = (item, index) => {
            article.value = {};
            selectRowIndex.value = index;

            nextTick(() => {
                article.value = item;
            });            
        }

        const calculateMargin = () => {
            docto.value.details.forEach((item) => {

                // Calcular `margen` evitando división por cero
                if (item.ultimo_Costo > 0) {
                    item.margen = parseFloat(((item.precio_Unitario - item.ultimo_Costo) / item.ultimo_Costo) * 100).toFixed(2)
                }
            });    
        }
        
        const searchDocument = async () => {
            selectRowIndex.value = -1;
            docto.value = {
                importe_Neto: 0,
                total_Impuestos: 0,
                details: [],
                taxes: [],
            };

            if (searchQuery.value === '') {
                helper.danger('Ingrese un código de barras');
                return;
            }

            loading.value = true;
            try {

                const query = {
                    doctoId: searchQuery.value,
                    tipoDocto: typeDocument.value,
                }

                const { data } = typeDocument.value == 'C' ? await DoctosVEResource.getDocto(query) : await DoctosPVResource.getDocto(query);
                if (data.isSuccesful) {

                    // Asigna el objeto `data.data` y reasigna propiedades
                    if (typeDocument.value === 'C') {
                        docto.value = {
                            ...data.data,  // Asigna todas las propiedades restantes                        
                            details: data.data.doctosVeDetail || [],  // Reasigna `doctosVeDetail` a `details`
                            taxes: data.data.impuestosDoctosVe || [], // Reasigna `impuestosDoctosVe` a `taxes`
                        };
                    } else {
                        docto.value = {
                            ...data.data,  // Asigna todas las propiedades restantes                        
                            details: data.data.doctosPvDetail || [],  // Reasigna `doctosPvDetail` a `details`
                            taxes: data.data.impuestosDoctosPv || [], // Reasigna `impuestosDoctosVe` a `taxes`
                        };
                    }                    

                    nextTick(() => {
                        docto.value.details.forEach((item) => {

                            // Calcular `margen` evitando división por cero
                            if (item.ultimo_Costo > 0) {
                                item.margen = parseFloat(((item.precio_Unitario - item.ultimo_Costo) / item.ultimo_Costo) * 100).toFixed(2)
                            }
                        });
                    });
                }
                else {
                    searchQuery.value = '';
                    helper.danger(data.message);
                }
            }catch(e) {
                helper.handleResponseErrors(e)
            } finally {
                searchQuery.value = '';
                loading.value = false;
            }
        }

        const getConnectionId = computed(() => {
            return store.getters['auth/getConexionId'];
        });

        const getBranchId = computed(() => {
            const branch = store.getters['auth/getBranch'];
            return branch ? branch.sucursal_Id : 0;
        });

        const totalMargin = computed(() => {
            if (!docto.value.details) return 0;
            return parseFloat(docto.value.details.reduce((acc, item) => {
                return acc + (item.margen * 1 );
            }, 0)).toFixed(2) * 1;
        });

        const totalCost = computed(() => {
            if (!docto.value.details) return 0;
            return parseFloat(docto.value.details.reduce((acc, item) => {
                return acc + (item.ultimo_Costo * 1 );
            }, 0)).toFixed(2) * 1;
        });

        const percentMargin = computed(() => {
            return (( (docto.value.importe_Neto / totalCost.value) - 1 ) * 100).toFixed(2) * 1;
        });

        onMounted(async () => {
            // Load data
        });

        return {
            loading,
            typeDocument,
            searchQuery,
            docto,
            article,
            lastPurchases,
            columnLastPurchases,
            activeTab,

            //
            totalMargin,
            totalCost,
            percentMargin,
            getConnectionId,
            getBranchId,

            //
            isSelected,
            selectRow,
            calculateMargin,
            resetForm,
            searchDocument,
        }
    },
}
</script>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: 350px; 
    /*calc(100vh - 360px)!important; /* Altura máxima del 100% de la altura de la ventana menos el espacio ocupado por el navbar y el footer */
}

</style>